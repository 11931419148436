<template>
    <CustomLoader v-if="loading" />

    <ReplyWithoutAccount
        v-else-if="!loading && surveyData"
        :surveyData="surveyData"
        :show-best-bank-section="false"
        @registerAndReplyNow="handleRegisterAndReplyNow"
    >
        <template #form>
            <div class="w-100 d-flex align-items-center flex-column mt-6">
                <button
                    @click.prevent="registerAndReplyNow"
                    class="btn main-btn submit-btn"
                    :data-kt-indicator="registerLoading ? 'on' : 'off'"
                    :disabled="registerLoading"
                >
                    <span class="indicator-label">{{ $t("Register & Reply Now") }}</span>
                    <span class="indicator-progress">
          {{ $t("pleaseWait") }}
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
                </button>

                <div class="buttons-divider">
                    {{ $t("or") }}
                </div>
                <button
                    class="btn main-btn submit-btn"
                    :data-kt-indicator="loadingOpenReply ? 'on' : 'off'"
                    :disabled="loadingOpenReply"
                    @click="handleReplyNow"
                >
                    <span class="indicator-label">{{ $t("Reply Now") }}</span>
                    <span class="indicator-progress">
            {{ $t("pleaseWait") }}
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
                </button>

                <div class="accept-terms-privacy-policy">
                    {{ $t("By choosing 'reply now' you accept our") }}
                    <span>
            <a :href="platformConfigs.platformLinks.Terms" target="_blank">{{ $t("Terms & Conditions") }}</a> &
            <a :href="platformConfigs.platformLinks.Privacy" target="_blank">{{ $t("Privacy Policy") }}</a>
          </span>
                </div>
            </div>
        </template>
    </ReplyWithoutAccount>

    <FeedbackReplyPopup
        v-if="feedbackDataContainer"
        :popupVisible="isFeedbackReplyPopupVisible"
        :feedbackDataContainer="feedbackDataContainer"
        @handleSaveOrSkip="handleSaveOrSkip"
        @onClose="handleCloseReplyFeedbackPopup"
    />
</template>

<script>
import ReplyWithoutAccount from "@/buying-teams/pages/business/feedbacks/reply-without-account/ReplyWithoutAccount";
import FeedbackReplyPopup from "@/buying-teams/pages/business/feedbacks/feedback-reply/FeedbackReplyPopup";
import { redirectToRouteWithName } from "@/buying-teams/helpers/UrlHelper";
import store from "@/store";
import { ReplyFeedbackDataContainer } from "@/store/models/business/feedback/ReplyFeedbackDataContainer";
import { SurveyModel } from "@/store/models/SurveyModel";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import ReplyActionTypeEnum from "@/store/enums/ReplyActionTypeEnum";
import { Mutations } from "@/store/enums/StoreEnums";
import { PlatformConfigsModel } from "@/store/models/shared/config/PlatformConfigsModel";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";

export default {
    name: "SocGenScreen",
    data() {
        return {
            loading: true,
            loadingOpenReply: false,
            queryDataId: null,
            isFeedbackReplyPopupVisible: false,
            feedbackDataContainer: null,
            surveyData: null,
            businessData: null,
            userData: null,
            registerLoading: false,
            ReplyActionTypeEnum,
            platformConfigs: new PlatformConfigsModel(PlatformTypeEnum.BUSINESS)
        };
    },
    components: {
        CustomLoader,
        FeedbackReplyPopup,
        ReplyWithoutAccount
    },
    created() {
        if (!this.$route.query.id) {
            redirectToRouteWithName("sign-in");
            return;
        }
        if (this.isUserAuthenticated) {
            store.commit(Mutations.PURGE_AUTH);
        }
        this.queryDataId = this.$route.fullPath.substring(this.$route.fullPath.indexOf("=") + 1);
        store.dispatch("getSurveyDataForReplay", this.queryDataId).then(res => {
            if (res.survey) {
                this.feedbackDataContainer = new ReplyFeedbackDataContainer({ ...res.survey, isSocGen: true });
                this.feedbackDataContainer.setSurveyDataId(this.queryDataId);
                this.surveyData = new SurveyModel(res.survey);
                this.businessData = res.business;
                this.userData = res.user;

                if (this.feedbackDataContainer.isCompleted) {
                    redirectToRouteWithName("sign-in");
                }
            }
        }).catch(() => {
            redirectToRouteWithName("sign-in");
        }).finally(() => {
            this.loading = false;
        });
    },
    methods: {
        handleReplyNow() {
            this.loadingOpenReply = true;
            this.handleUpdateNoAuthBusiness()
                .then(() => {
                    this.isFeedbackReplyPopupVisible = true;
                })
                .finally(() => {
                    this.loadingOpenReply = false;
                });
        },
        registerAndReplyNow() {
            this.registerLoading = true;
            this.handleUpdateNoAuthBusiness()
                .then(() => {
                    this.$router.push({ name: "register-sg", query: { id: this.queryDataId } });
                })
                .finally(() => {
                    this.registerLoading = false;
                });
        },
        handleUpdateNoAuthBusiness() {
            return new Promise((resolve, reject) => {
                store.dispatch("updateNoAuthBusiness", {
                    first_name: this.userData.first_name,
                    last_name: this.userData.last_name,
                    company_name: this.businessData.business_name,
                    country: this.businessData.hq,
                    industries: [this.businessData.industry],
                    num_employees: this.businessData.num_employees,
                    revenue: this.businessData.revenue,
                    data_id: this.queryDataId,
                    business_id: this.businessData.business_id
                }).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
            });
        },
        handleCloseReplyFeedbackPopup() {
            if (this.feedbackDataContainer.isCompleted) {
                redirectToRouteWithName("sign-in");
                return;
            }
            this.isFeedbackReplyPopupVisible = false;
        },
        handleSaveOrSkip({ action, data }) {
            delete data.survey_id;
            data.data_id = this.queryDataId;
            this.feedbackDataContainer.setSubmittingLoadingStatus(true);
            store.dispatch(action === ReplyActionTypeEnum.REPLY ? "replyToSurveyNoAuth" : "skipQuestionNoAuth", data)
                .then(async (res) => {
                    if (res) {
                        if (res.is_survey_completed) {
                            await store.dispatch("getSurveysStatsNoAuth", this.queryDataId)
                                .then((res) => {
                                    this.feedbackDataContainer.setCompletedStats(res);
                                });
                            this.feedbackDataContainer.setCompleted();
                        } else {
                            this.feedbackDataContainer.nextQuestion();
                        }
                    }
                })
                .catch(err => {
                    console.error("handleSaveOrSkip ", err);
                })
                .finally(() => {
                    this.feedbackDataContainer.setSubmittingLoadingStatus(false);
                });
        },
        handleRegisterAndReplyNow() {
            this.handleUpdateNoAuthBusiness().then(() => {
                this.$router.push({ name: "register-sg", query: { id: this.queryDataId } });
            });
        }
    },
    computed: {
        isUserAuthenticated() {
            return store.getters.isUserAuthenticated;
        }
    }
};
</script>
<style lang="scss" scoped>
.buttons-divider {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #ABABAB;
    margin: 16px 0;
}

.accept-terms-privacy-policy {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #ABABAB;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;

    a {
        color: #ABABAB;
        text-decoration: underline;
    }
}
</style>
